<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Estadisticas de cambios de precio
    </h1>

    <div class="invoice-div-bottom-div">
      <div class="container global-list-main-container">
        <b-tabs type="is-boxed">
          <b-tab-item
            label="Gráfico"
            icon="chart-areaspline"
            style="padding-top: 2rem"
          >
            <ProvidersArticlesChangesGraph
              :information="providerArticlesInformation"
            ></ProvidersArticlesChangesGraph>
          </b-tab-item>
          <b-tab-item label="Historial de cambios" icon="file-chart-outline">
            <ProvidersArticlesTable
              :information="providerArticlesInformation"
            ></ProvidersArticlesTable>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ProvidersArticlesTable from "./ProvidersArticlesTable";
import ProvidersArticlesChangesGraph from "./ProvidersArticlesChangesGraph";
// @ is an alias to /src
export default {
  name: "ProvidersArticlesGraph",
  props: ["providerArticlesInformation"],
  components: {
    ProvidersArticlesTable,
    ProvidersArticlesChangesGraph,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
