<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Precios de proveedores </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-tooltip
          class="special-simple-margin"
          v-if="providers.length === 0 || articles.length === 0"
          label="Primero debes agregar artículos y proveedores al sistema o esperar a que el sistema cargue estos datos"
          multilined
        >
          <b-button type="is-primary" disabled
            >Agregar precios de proveedor</b-button
          >
        </b-tooltip>
        <b-button
          type="is-primary"
          @click="openAddProvidersPrice()"
          v-if="providers.length > 0 && articles.length > 0"
          >Agregar precios de proveedor</b-button
        >
      </div>
      <div class="control">
        <b-tooltip
          class="special-simple-margin"
          v-if="
            providers.length === 0 ||
            (articles.length === 0 &&
              companyPaymentPlanModules.main.importProvidersPrices === 'S' &&
              permissions &&
              permissions.main.importProvidersPrices === 'S')
          "
          label="Primero debes agregar artículos y proveedores al sistema o esperar a que el sistema cargue estos dato"
          multilined
        >
          <b-button type="is-primary" disabled
            >Importar precios de proveedores</b-button
          >
        </b-tooltip>
        <b-button
          type="is-primary"
          @click="startImport()"
          v-if="
            providers.length > 0 &&
            articles.length > 0 &&
            companyPaymentPlanModules.main.importProvidersPrices === 'S' &&
            permissions &&
            permissions.main.importProvidersPrices === 'S'
          "
          >Importar precios de proveedores</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startExport()"
          v-if="
            companyPaymentPlanModules.main.exportProvidersPrices === 'S' &&
            permissions &&
            permissions.main.exportProvidersPrices === 'S'
          "
          >Exportar precios de proveedores</b-button
        >
      </div>
      <div class="control special-margin">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>

      <div class="global-lists-filters-container">
        <b-field label="Filtrar por proveedor" label-position="inside">
          <b-select
            placeholder="Filtrar por proveedor"
            icon="account-group-outline"
            v-model="filterProvider"
            class="global-lists-filters-control"
          >
            <option value="">Todos los provedores</option>
            <option
              v-for="provider in providers"
              :value="provider"
              :key="provider.NOMBRE_PRV"
            >
              {{ provider.NOMBRE_PRV }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de artículos: <b>{{ filteredArticles.length }}</b>
    </div>

    <b-table
      pagination-position="both"
      :data="filteredArticles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedArticles"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="Articles"
      paginated
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Precios de ${row.CLAVE_ART}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      checkbox-position="left"
      default-sort="CLAVE_ART"
    >
      <template #empty>
        <div class="has-text-centered">No hay articulos o proveedores</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :visible="column.display"
          :key="column.field"
          :label="column.label"
          :cell-class="checkRedArticle(props.row) ? 'red-cell' : ''"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>
          <span>{{ props.row[column.field] }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="content">
            <p
              v-if="
                props.row.PRECIOS_PROV.length === 0 || !props.row.PRECIOS_PROV
              "
            >
              No hay precios de proveedores
            </p>
            <div class="content" v-if="props.row.PRECIOS_PROV.length > 0">
              <b-field
                label="Lista de precios"
                v-if="props.row.COSTO_MIN_VTA"
                expanded
                label-position="inside"
                class="special-margin required-input global-select-full-width"
                required
              >
                <b-select
                  placeholder="Filtrar por proveedor"
                  icon="account-group-outline"
                  v-model="props.row.selectedDefaultListPrices"
                  @change.native="updateMinPrice(props.row)"
                >
                  <option
                    v-for="singleListPrice in props.row.defaultListPrices"
                    :value="singleListPrice"
                    :key="singleListPrice._id"
                  >
                    {{ singleListPrice.NOMBRE_LISTAPT }}
                  </option>
                </b-select>
              </b-field>

              <b-field
                label="Costo mínimo de venta"
                expanded
                v-if="props.row.COSTO_MIN_VTA"
                label-position="inside"
                class="special-margin required-input"
                required
                :type="{ 'is-danger': checkRedArticle(props.row) }"
                :message="{
                  'El costo mínimo de venta es menor a uno o mas precios de compra, esta comprando más caro de lo que vende 😔':
                    checkRedArticle(props.row),
                }"
              >
                <MoneyInput
                  currency="MXN"
                  :digits="5"
                  placeholder="Ejemplo: 16"
                  icon="cash-multiple"
                  :value="props.row.COSTO_MIN_VTA_ACT"
                  disabled
                  :required="true"
                >
                </MoneyInput>
              </b-field>

              <b-field
                label="Costo mínimo de venta con impuestos"
                expanded
                v-if="props.row.COSTO_MIN_VTA"
                label-position="inside"
                class="special-margin required-input"
                required
              >
                <MoneyInput
                  currency="MXN"
                  :digits="5"
                  placeholder="Ejemplo: 16"
                  icon="cash-multiple"
                  :value="props.row.COSTO_MIN_VTA_ACT_IMP"
                  disabled
                  :required="true"
                >
                </MoneyInput>
              </b-field>

              <div>
                <div
                  v-for="(singleProvider, index) in props.row.PRECIOS_PROV"
                  v-bind:key="index + 100"
                  class="single-articles-prices special-margin"
                >
                  <h3
                    class="special-margin"
                    v-if="
                      singleProvider.FECHA_ACT &&
                      getProviderName(singleProvider.CLAVE_PRV)
                    "
                  >
                    <strong>Precio de </strong>
                    <strong
                      ><span class="global-form-hint-color">{{
                        getProviderName(singleProvider.CLAVE_PRV)
                      }}</span></strong
                    >
                    actualizado el
                    <strong
                      ><span class="global-form-hint-color">{{
                        formatedDate(singleProvider.FECHA_ACT)
                      }}</span></strong
                    >
                  </h3>

                  <h3
                    class="special-margin"
                    v-if="
                      !singleProvider.FECHA_ACT &&
                      getProviderName(singleProvider.CLAVE_PRV)
                    "
                  >
                    <strong>Precio de </strong>
                    <strong
                      ><span class="global-form-hint-color">{{
                        getProviderName(singleProvider.CLAVE_PRV)
                      }}</span></strong
                    >
                  </h3>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Costo actual de compra del proveedor (Requerido)"
                      expanded
                      label-position="inside"
                    >
                      <MoneyInput
                        currency="MXN"
                        :digits="5"
                        placeholder="Ejemplo: 40"
                        icon="account-cash"
                        v-model="singleProvider.COSTO"
                        :disabled="false"
                        :required="true"
                      >
                      </MoneyInput>
                    </b-field>

                    <b-field
                      label="Costo pasado de compra del proveedor"
                      expanded
                      label-position="inside"
                    >
                      <MoneyInput
                        currency="MXN"
                        :digits="5"
                        placeholder="Ejemplo: 40"
                        icon="account-cash-outline"
                        v-model="singleProvider.COSTO_ANT"
                        :disabled="true"
                        :required="true"
                      >
                      </MoneyInput>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Costo actual de compra del proveedor con descuentos e indirectos (Requerido)"
                      expanded
                      label-position="inside"
                    >
                      <MoneyInput
                        currency="MXN"
                        :digits="5"
                        placeholder="Ejemplo: 40"
                        icon="account-cash"
                        :value="getProviderFinalPrice(singleProvider)"
                        :disabled="true"
                        :required="true"
                      >
                      </MoneyInput>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Clave del artículo del proveedor (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 40HB3"
                        expanded
                        icon="pencil-outline"
                        v-model="singleProvider.CLAVE_ARTPRV"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Descripción del articulo del provedor (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: Descripción de aceros"
                        expanded
                        icon="pencil"
                        v-model="singleProvider.DESCRIP_ARTPRV"
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Codigos de barras (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 40HB3"
                        expanded
                        icon="barcode"
                        v-model="singleProvider.CODIGO_BARR"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Múltiplo de unidades permitidas en compra (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        type="number"
                        step="any"
                        placeholder="Ejemplo: 5"
                        icon="numeric-1"
                        v-model="singleProvider.MULTIPLO_COMPRA"
                      />
                    </b-field>
                  </b-field>

                  <div class="controls-no-margin">
                    <b-button
                      type="is-primary"
                      class="special-margin"
                      v-if="
                        companyPaymentPlanModules &&
                        companyPaymentPlanModules.purchase
                          .saveProviderArticleCost === 'S' &&
                        permissions &&
                        permissions.purchase.saveProviderArticleCost === 'S'
                      "
                      @click="
                        confirmChangeProvidersPrices(
                          props.row,
                          singleProvider,
                          true
                        )
                      "
                      >Seleccionar precio de proveedor</b-button
                    >

                    <b-button
                      type="is-primary"
                      class="special-margin"
                      @click="openProvideerDiscounts(props.row, singleProvider)"
                      v-if="
                        companyPaymentPlanModules &&
                        companyPaymentPlanModules.purchase
                          .saveProviderDiscounts === 'S' &&
                        permissions &&
                        permissions.purchase.saveProviderDiscounts === 'S'
                      "
                      >Ver descuento de proveedor</b-button
                    >
                    <b-button
                      type="is-primary"
                      class="special-margin"
                      @click="openProvideerCosts(props.row, singleProvider)"
                      v-if="
                        companyPaymentPlanModules &&
                        companyPaymentPlanModules.purchase.saveProviderCosts ===
                          'S' &&
                        permissions &&
                        permissions.purchase.saveProviderCosts === 'S'
                      "
                      >Ver indirectos de proveedor</b-button
                    >
                    <b-button
                      type="is-primary"
                      class="special-margin"
                      @click="openProviderArticlesStatistics(singleProvider)"
                      v-if="
                        props.row.PRECIOS_PROV &&
                        props.row.PRECIOS_PROV.length > 0
                      "
                      >Ver estadistica de cambios de precio</b-button
                    >
                    <b-button
                      type="is-danger"
                      class="special-margin"
                      v-if="
                        companyPaymentPlanModules.purchase
                          .deleteProviderPrice === 'S' &&
                        permissions.purchase.deleteProviderPrice === 'S'
                      "
                      @click="
                        startCancelEvent(
                          'danger',
                          function () {
                            deleteProviderPrice(singleProvider);
                          },
                          'eliminar el precio de proveedor'
                        )
                      "
                      >Eliminar precio de proveedor</b-button
                    >
                  </div>
                </div>
              </div>

              <div class="details-controls-container">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="confirmChangeProvidersPrices(props.row)"
                    >Guardar cambios</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import Cleave from "cleave.js";
import ImportFileModal from "../../Global/components/ImportFileModal";
import AddProvidersArticlesModal from "./AddProvidersArticlesModal";
import ProvidersArticlesGraph from "./ProvidersArticlesChanges";
import AddDiscount from "./purchase-orders-actions/AddDiscount";
import AddCost from "./purchase-orders-actions/AddCost";
import moment from "moment";
import Masks from "../../../data/cleave-masks";
import { openConfirmationModalDanger } from "../../../utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "ProvidersListArticles",
  props: ["specialComponent"],
  components: {},
  directives: { cleave },
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedArticles: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      masks: Masks,
      filterProvider: "",
      refreshLoading: false,
    };
  },
  mounted() {
    if (!this.specialComponent) {
      this.$store.dispatch("CHANGEACTIVE", "providerPrices");
    }
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETARTICLES"));
      allPromises.push(this.$store.dispatch("GETPRICESLISTS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    formatedDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    openProvideerDiscounts(article, providerDiscount) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddDiscount,
        props: {
          isProvider: true,
          article: article,
          item: providerDiscount,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openProvideerCosts(article, providerCost) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCost,
        props: {
          isProvider: true,
          article: article,
          item: providerCost,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddProvidersPrice() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddProvidersArticlesModal,
        props: {},
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    confirmExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de proveedores",
        message: `¿Desea confirmar la exportación de los proveedores a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "providersArticlesPrices",
          nameImport: "Precios de proveedores",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async confirmChangeProvidersPrices(
      priceInformation,
      providerInformation,
      isSingleProviderChange = false
    ) {
      // Check if popup needs to be showed to the user because its loosing money
      let foundRedPrice = false;
      for (const singlePrice of priceInformation.PRECIOS_PROV) {
        if (singlePrice.COSTO <= 0) {
          this.$buefy.dialog.confirm({
            title: "Cambiar precio de proveedor",
            message:
              "El nuevo precio de proveedor no puede ser menor o igual a 0",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        if (singlePrice.COSTO >= priceInformation.COSTO_MIN_VTA_ACT) {
          foundRedPrice = true;
          this.$buefy.dialog.confirm({
            title: "Cambiar precio de proveedor",
            message:
              "El nuevo precio de proveedor es mayor al costo minimo de venta. Se esta vendiendo sin ganancia. ¿Esta seguro que desea continuar?",
            confirmText: "Cambiar precio de proveedor",
            type: "is-danger",
            hasIcon: true,
            onCancel: () => {},
            onConfirm: async () => {
              await this.saveProvidersPrices(priceInformation);
              if (isSingleProviderChange) {
                await this.selectProviderPrice(
                  priceInformation,
                  providerInformation,
                  false
                );
              }
            },
          });
        }
      }

      if (!foundRedPrice) {
        await this.saveProvidersPrices(priceInformation);
        if (isSingleProviderChange) {
          await this.selectProviderPrice(priceInformation, providerInformation);
        }
      }
    },
    async saveProvidersPrices(priceInformation) {
      try {
        let response = await this.$store.dispatch(
          "SAVEPROVIDERSPRICES",
          priceInformation.PRECIOS_PROV
        );
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Se guardó exitosamente los cambios de " +
              priceInformation.CLAVE_ART,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar los cambios de " +
              priceInformation.CLAVE_ART,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar los cambios de " +
            priceInformation.CLAVE_ART,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    updateMinPrice(article) {
      let finalMinimumCost = 0;
      // Check if the product has COSTO_MIN_VTA
      if (article.COSTO_MIN_VTA && article.selectedDefaultListPrices) {
        // Add the utility margin to the calculation
        finalMinimumCost +=
          article.COSTO_MIN_VTA *
          (article.selectedDefaultListPrices.PORC_UTILID / 100);

        // Add the Merma to the calculation
        if (article.PORC_MERMA)
          finalMinimumCost +=
            article.COSTO_MIN_VTA * (article.PORC_MERMA / 100);

        // Add other cost to the calculation
        if (article.PORC_INDIREC)
          finalMinimumCost +=
            article.COSTO_MIN_VTA * (article.PORC_INDIREC / 100);

        // Add shipping cost to the calculation
        if (article.PORC_FLETE)
          finalMinimumCost +=
            article.COSTO_MIN_VTA * (article.PORC_FLETE / 100);

        finalMinimumCost += article.COSTO_MIN_VTA;

        // Calculate discounts using watterfall method
        for (const singleDiscount of article.selectedDefaultListPrices
          .DESCUENTOS) {
          finalMinimumCost -= Number(singleDiscount.CANTIDAD);
        }

        // Calculate costs using watterfall method after discounts calculation
        for (const singleCost of article.selectedDefaultListPrices.COSTOS) {
          finalMinimumCost += Number(singleCost.CANTIDAD);
        }

        // Change the current COSTO_MIN_VTA_ACT to the calculation result
        article.COSTO_MIN_VTA_ACT = parseFloat(finalMinimumCost).toFixed(5);

        let finalMinimumCostWithTaxes = finalMinimumCost;

        if (article.CANT_IVA) {
          finalMinimumCostWithTaxes +=
            finalMinimumCost * (article.CANT_IVA / 100);
        }

        if (article.CANT_IEPS) {
          finalMinimumCostWithTaxes +=
            finalMinimumCost * (article.CANT_IEPS / 100);
        }

        // Change the current COSTO_MIN_VTA_ACT_IMP to the calculation result
        article.COSTO_MIN_VTA_ACT_IMP = parseFloat(
          finalMinimumCostWithTaxes
        ).toFixed(5);
      }
    },
    openProviderArticlesStatistics(providerArticleInformation) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ProvidersArticlesGraph,
        props: {
          providerArticlesInformation: providerArticleInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    checkRedArticle(rowInformation) {
      let isRedArticle = false;
      for (const singlePrice of rowInformation.PRECIOS_PROV) {
        if (singlePrice.COSTO >= rowInformation.COSTO_MIN_VTA_ACT) {
          isRedArticle = true;
          return isRedArticle;
        }
      }
      return isRedArticle;
    },
    getProviderName(providerId) {
      for (const singleProvider of this.providers) {
        if (singleProvider._id === providerId) {
          return singleProvider.NOMBRE_PRV;
        }
      }
    },
    async selectProviderPrice(
      articleInformation,
      providerInformation,
      showMessages = true
    ) {
      try {
        let response = await this.$store.dispatch("SAVEPROVIDERARTICLECOST", {
          articleId: articleInformation._id,
          cost: providerInformation.COSTO,
        });
        if (showMessages) {
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el nuevo costo mínimo de venta",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {},
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al guardar el costo minimo de venta",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar el costo minimo de venta",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de precios de proveedores",
        message: `¿Desea confirmar la exportación de los precios de proveedores a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    exportData() {
      let providersPrices = [];
      for (let singleArticle of this.articles) {
        // Fill object with needed values
        for (const singlePrice of singleArticle.PRECIOS_PROV) {
          let newObjectExport = {};
          // search for provider key
          for (const singleProvider of this.providers) {
            if (singleProvider._id === singlePrice.CLAVE_PRV) {
              newObjectExport["CLAVE DEL PROVEEDOR (Requerido)"] =
                singleProvider.CLAVE_PRV;
            }
          }
          newObjectExport["CLAVE DEL ARTICULO (Requerido)"] =
            singleArticle.CLAVE_ART;
          newObjectExport["CLAVE DEL ARTICULO DEL PROVEEDOR (Opcional)"] =
            singlePrice.CLAVE_ARTPRV ? singlePrice.CLAVE_ARTPRV : "";
          newObjectExport["PRECIO DEL PROVEEDOR (Requerido)"] =
            singlePrice.COSTO;
          newObjectExport["COSTO MINIMO DEL ARTICULO (Opcional)"] = "";
          newObjectExport[
            "MULTIPLO DE UNIDADE PERMITIDAS EN COMPRA (Opcional)"
          ] = singlePrice.MULTIPLO_COMPRA ? singlePrice.MULTIPLO_COMPRA : 0;
          newObjectExport["ACTUALIZAR COSTO MINIMO DEL ARTICULO (Requerido)"] =
            "N";

          if (singleArticle.COSTO_MIN_VTA === singlePrice.COSTO) {
            newObjectExport["COSTO MINIMO DEL ARTICULO (Opcional)"] =
              singleArticle.COSTO_MIN_VTA;
            newObjectExport[
              "ACTUALIZAR COSTO MINIMO DEL ARTICULO (Requerido)"
            ] = "S";
          }

          providersPrices.push(newObjectExport);
        }
      }
      let payload = {
        data: providersPrices,
        name: "precios-proveedores-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    getProviderFinalPrice(providerInformation) {
      let currentPrice = providerInformation.COSTO;

      // Calculate discounts using watterfall method
      for (const singleDiscount of providerInformation.DESCS) {
        currentPrice -= singleDiscount.CANTIDAD;
      }

      // Calculate costs using watterfall method after discounts calculation
      for (const singleCost of providerInformation.COSTOS) {
        currentPrice += singleCost.CANTIDAD;
      }

      return currentPrice;
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async deleteProviderPrice(providerArticleCost) {
      try {
        let response = await this.$store.dispatch("DELETEPROVIDERARTICLECOST", {
          providerArticleCost: providerArticleCost,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se borró exitosamente el costo del proveedor",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al borrar el costo del proveedor",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al borrar el costo del proveedor",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    priceLists() {
      let currentPriceLists = this.$store.getters.PRICES_LISTS.map(
        (singlePriceList) => {
          let singlePriceListWithProps = { ...singlePriceList };
          singlePriceListWithProps.formatedUtility =
            singlePriceListWithProps.PORC_UTILID + "%";
          return singlePriceListWithProps;
        }
      );
      return currentPriceLists;
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        let defaultListPrices = [];
        /**
         * Add default list prices
         */
        for (let singlePriceList of this.priceLists) {
          let foundPriceList = false;
          for (let singleArticlePriceList of singleArticle.LISTAS_PREC) {
            if (singlePriceList._id === singleArticlePriceList.CLAVE_LISTAP) {
              defaultListPrices.push(singleArticlePriceList);
              foundPriceList = true;
              break;
            }
          }
          if (!foundPriceList) {
            if (singleArticle.COSTO_MIN_VTA) {
              let formatedPriceList = { ...singlePriceList };
              formatedPriceList.NOMBRE_LISTAPT = singlePriceList.CLAVE_LISTAP;
              formatedPriceList.CLAVE_LISTAP = singlePriceList._id;
              formatedPriceList.DESCUENTOS = [];
              formatedPriceList.COSTOS = [];
              delete formatedPriceList._id;
              defaultListPrices.push(formatedPriceList);
            }
          }
        }

        articleWithProps.defaultListPrices = defaultListPrices;

        if (singleArticle.FE_ULTVTA) {
          articleWithProps.lastSaleFormat = moment(
            singleArticle.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }

        // Order by minimum percentage of utility
        let minPrice = articleWithProps.defaultListPrices[0];
        for (const singleList of articleWithProps.defaultListPrices) {
          if (singleList.PORC_UTILID <= minPrice.PORC_UTILID) {
            minPrice = singleList;
          }
        }
        articleWithProps.selectedDefaultListPrices = minPrice;

        // Order by default price list checkbox
        for (const singleList of articleWithProps.defaultListPrices) {
          for (const [
            singlePriceListIndex,
            singlePriceList,
          ] of this.priceLists.entries()) {
            if (
              singleList.CLAVE_LISTAP.toString() ===
                singlePriceList._id.toString() &&
              singlePriceList.LISTA_PRECIOS_BASE
            ) {
              articleWithProps.defaultListPrices[0] =
                articleWithProps.defaultListPrices.splice(
                  singlePriceListIndex,
                  1,
                  articleWithProps.defaultListPrices[0]
                )[0];
              articleWithProps.selectedDefaultListPrices =
                articleWithProps.defaultListPrices[0];
            }
          }
        }

        articleWithProps.COSTO_MIN_VTA_ACT = articleWithProps.COSTO_MIN_VTA;
        this.updateMinPrice(articleWithProps);
        return articleWithProps;
      });
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
    filteredArticles() {
      if (this.filterProvider === "") {
        return this.articles.map((singleArticle) => {
          let articleWithProps = { ...singleArticle };
          return articleWithProps;
        });
      }
      return this.articles.filter((singleArticle) => {
        for (let singleProviderPrice of singleArticle.PRECIOS_PROV) {
          if (singleProviderPrice.CLAVE_PRV === this.filterProvider._id) {
            return true;
          }
        }
        return false;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
