<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar precios de proveedores</h1>

    <div class="control add-global-controls">
      <b-button type="is-primary" class="special-margin" @click="pushPrice()"
        >Agregar precio extra</b-button
      >
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Los precios con
      campos vacios o en 0 seran
      <span class="global-form-hint-color"> ignorados. </span>
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="saveProviderPrices()"
        >Guardar precios de proveedores</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>

    <div v-for="(item, index) in prices" :key="index">
      <h3 class="form-division">
        Precio de proveedor {{ prices.length - index }}
      </h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Artículo (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
        >
          <b-autocomplete
            :data="articles"
            field="DESCRIPCION"
            v-model="item.ART"
            open-on-focus
            placeholder="Ejemplo: Pala"
            expanded
            icon="screwdriver"
            clearable
            @select="(option) => (item.CLAVE_ART = option ? option._id : '')"
            :loading="isFetching"
            @typing="searchArticles"
          >
            <template #empty>No se encontraron artículos</template>
          </b-autocomplete>
        </b-field>

        <b-field
          label="Proveedor (Requerido)"
          expanded
          label-position="inside"
          class="required-input"
          required
        >
          <b-autocomplete
            :data="autoCompleteProviders(item.PRV)"
            field="NOMBRE_PRV"
            v-model="item.PRV"
            open-on-focus
            placeholder="Ejemplo: Proveedor 1"
            expanded
            icon="account"
            clearable
            @select="(option) => (item.CLAVE_PRV = option ? option._id : '')"
            check-infinite-scroll
          >
            <template #empty>No se encontraron proveedores</template>
          </b-autocomplete>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Precio (Requerido)" expanded label-position="inside">
          <MoneyInput
            currency="MXN"
            :digits="2"
            placeholder="Ejemplo: 3000"
            icon="cash-100"
            :required="true"
            v-model="item.COSTO"
            :disabled="false"
          />
        </b-field>

        <b-field
          label="Múltiplo de unidades permitidas en compra (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            type="number"
            step="any"
            placeholder="Ejemplo: 5"
            icon="numeric-1"
            v-model="item.MULTIPLO_COMPRA"
          />
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Clave del Artciulo del Proveedor (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 40HB3"
            expanded
            icon="pencil-outline"
            v-model="item.CLAVE_ARTPRV"
          ></b-input>
        </b-field>

        <b-field
          label="Descripción del articulo del provedor (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Descripción de aceros"
            expanded
            icon="pencil"
            v-model="item.DESCRIP_ARTPRV"
          ></b-input>
        </b-field>
      </b-field>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import debounce from "lodash/debounce";
export default {
  name: "AddProvidersArticlesModal",
  props: ["providersContacts"],
  components: {},
  data() {
    return {
      currentPrices: 0,
      prices: [],
      articleAutocomplete: "",
      providerAutocomplete: "",
      isFetching: false,
    };
  },
  async created() {},
  mounted() {
    this.pushPrice();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    pushPrice() {
      let tempPrice = {
        NOMBRE_PRV: "",
        CLAVE_ART: "",
        ART: "",
        CLAVE_PRV: "",
        PRV: "",
        COSTO: 0,
        COSTO_ANT: 0,
        MULTIPLO_COMPRA: 0,
        CLAVE_ARTPRV: "",
        DESCRIP_ARTPRV: "",
        CODIGO_BARR: "",
      };
      this.prices.unshift(tempPrice);
      this.currentPrices++;
    },
    removeNotValid() {
      this.prices = this.prices.filter((singlePrice) => {
        if (!singlePrice.CLAVE_ART) return false;
        if (!singlePrice.CLAVE_PRV) return false;
        if (!singlePrice.COSTO) return false;
        return true;
      });
    },
    async saveProviderPrices() {
      try {
        this.removeNotValid();
        let response = await this.$store.dispatch(
          "SAVEPROVIDERSPRICES",
          this.prices
        );
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardó exitosamente los precios de proveedor",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al guardar los precios de proveedor",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar los precios de proveedor",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    searchArticles: debounce(async function (search) {
      try {
        if (!search.length) {
          this.data = [];
          return;
        }

        this.isFetching = true;

        await this.$store.dispatch("SEARCHARTICLES", {
          skip: 0,
          limit: 50,
          search: search,
        });

        this.isFetching = false;
      } catch (e) {
        this.isFetching = false;
      }
    }, 300),
    autoCompleteProviders(value) {
      if (!this.providers) {
        return [];
      }
      return this.providers.filter((singlProvider) => {
        return (
          singlProvider.NOMBRE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0 ||
          singlProvider.CLAVE_PRV.toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) >= 0
        );
      });
    },
  },
  computed: {
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        return providerWithProps;
      });
    },
    articles() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
